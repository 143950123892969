import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { TenantConfigurationType } from '@parashift/shared/types';
import { TenantConfiguration } from '@parashift/shared/models';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => TenantConfiguration,
  endpointUrl: TenantConfigurationType,
})
export class TenantConfigurationService extends BaseApiService<TenantConfiguration> {
  className = TenantConfigurationService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version;
  }
}
